import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/Layout/PageWrapper';
import Container from '../components/Layout/SharedStyles/Container';
import CollectionHero from '../components/Hero/CollectionHero';
import Lead from '../components/Lead/Lead';
import CardGridSecondary from '../components/CardGridSecondary/CardGridSecondary';
import ProductGrid from '../components/Product/ProductGrid';
import ModuleArea from '../components/ModularContent/ModuleArea';

const AllProductsPage = ({
  data: {
    datoCmsAllProductsPage: {
      title,
      id,
      metaTags,
      heroImage,
      heroIcon,
      heroVideo,
      leadHeading,
      leadSubheading,
      modularContent,
      collections,
      products,
      productsHeading,
      leadText,
    },
    homeCrumb,
  },
  pageContext,
}) => {
  const breadcrumbs = [
    { title: homeCrumb.title, id: homeCrumb.id },
    { title: title, id: id },
  ];

  return (
    <PageWrapper
      hasHero={true}
      breadcrumbs={breadcrumbs}
      pageData={pageContext}
      metaTags={metaTags}
    >
      {/* <PageIntro title={title} breadcrumbs={breadcrumbs} /> */}
      <CollectionHero image={heroImage} icon={heroIcon} video={heroVideo} />
      <Container width="large" gutters pt={4} pb={3}>
        <Lead heading={leadHeading} text={leadText} button={false} />
      </Container>
      <Container width="large" gutters pt={1} pb={2}>
        <CardGridSecondary cards={collections} />
      </Container>
      <Container width="large" gutters pt={2} pb={2}>
        <ProductGrid products={products} heading={productsHeading} />
      </Container>
      {modularContent.length > 0 && <ModuleArea blocks={modularContent} />}
    </PageWrapper>
  );
};

export default AllProductsPage;

export const query = graphql`
  query AllProductsQuery($locale: String!) {
    datoCmsAllProductsPage(locale: { eq: $locale }) {
      locale
      title
      metaTags {
        title
        description
        image {
          url
        }
      }
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "800", fit: "crop", w: "1920", q: 60 }
        )
        alt
      }
      heroVideo {
        url
      }
      heroIcon {
        url
      }
      modularContent {
        ... on DatoCmsDetailedInfoBlock {
          ...DetailedInfoBlock
        }
        ... on DatoCmsLeadBlock {
          ...LeadBlock
        }
        ... on DatoCmsTestimonialsBlock {
          ...TestimonialsBlock
        }
        ... on DatoCmsGalleryBlock {
          ...GalleryBlock
        }
        ... on DatoCmsContentBlock {
          ...Content
        }
        ... on DatoCmsCollectionsListBlock {
          ...CollectionsListBlock
        }
        ... on DatoCmsCardGridBlock {
          ...CardGridBlock
        }
      }
      leadHeading
      leadSubheading
      leadText
      productsHeading
      products {
        ...ProductCard
      }
      collections {
        featureImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            imgixParams: { h: "500", fit: "crop", w: "600", q: 60 }
          )
          alt
        }
        title
        linkOverride
        id: originalId
        excerpt
      }
      id: originalId
    }
    homeCrumb: datoCmsHomepage(locale: { eq: $locale }) {
      locale
      title
      id: originalId
    }
  }
`;
