import React from 'react';
import style from './card-grid.mod.scss';
import Card from './Card';

const CardGridSecondary = ({ cards }) => {
  return (
    <div className={style.grid}>
      <div className={style.grid__grid}>
        {cards.map((card) => (
          <Card key={card.heading} buttonText={card.linkOverride} {...card} />
        ))}
      </div>
    </div>
  );
};

export default CardGridSecondary;
