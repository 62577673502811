import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from '../Button/Button';
import style from './card.mod.scss';

const Card = ({ featureImage, excerpt, title, id, buttonText }) => {
  return (
    <div className={style.card}>
      <div className={style.card__image}>
        <GatsbyImage
          className={style.card__image_gatsby}
          image={featureImage.gatsbyImageData}
          alt={featureImage.alt || ''}
        />
      </div>
      <div className={style.card__content}>
        <div>
          <h3 className={style.card__heading}>{title}</h3>
          <p className={style.card__text}>{excerpt}</p>
        </div>
        <Button
          size={'medium'}
          className={style.card__button}
          primary={true}
          fullWidth={true}
          recordId={id}
        >
          {buttonText || `View ${title}`}
        </Button>
      </div>
    </div>
  );
};

export default Card;
